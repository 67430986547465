import { Carousel } from '@mantine/carousel';
import { useMediaQuery } from '@mantine/hooks';
import { createStyles, Image, useMantineTheme } from '@mantine/core';
import Card, { CardItem } from './slideshow-card';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


export interface CardItemList { 
  itemList: CardItem[];
}

export default function BookSlideShow({itemList}: CardItemList) {
  const theme = useMantineTheme();
  //const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
  const slides = itemList.map((item) => (
    <SwiperSlide key={item.key}>
      <Card {...item} />
    </SwiperSlide>
  ));

  return (   
      <>
        <Swiper          
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {slides}
        </Swiper>
      </>
    );  
}