import { Image, Text, Title, createStyles, Menu, ActionIcon } from '@mantine/core';
import { IconBrandInstagram, IconBrandFacebook, IconShoppingCart, IconMenu2, IconInfoCircle, IconMail, IconComet } from '@tabler/icons';
import * as Constants from '../constants'
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';
import logoNoText from '../assets/logo_no_text.jpg'

const useStyles = createStyles((theme) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: "row",
    height: '60px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  itemsContainer: {
    paddingTop: "3px",
    paddingBottom: "3px",
    fontFamily: "Arial",
    display: 'flex',
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: Constants.MaxContentWidth,
    width: '100%',
    margin: '0 auto',// to center div.    
    alignItems: 'center'
  },
  actionContainer: {
    display: 'flex',
    flexdirection: "row",
    paddingRight: "12px",
    paddingLeft: "3px",
  },
  action: {
    marginRight: "5px"
  },
  titleSection: {
    display: 'flex',
    flexdirection: "row",
    alignItems: 'center'
  },
  title: {
    paddingLeft: '15px',
  },
  logo: {
    paddingTop: '5px',
    paddingLeft: '10px'
  },
  dropdownMenu:{
    paddingRight: '5px'
  }
}));

export default function Banner() {

  const { classes } = useStyles();

  return (

    <div className={`${classes.wrapper} `}>
      <div className={`${classes.itemsContainer}`}>

        {useCheckMobileScreen() == true
          ? MobileBannerLogo()
          : DesktopBannerLogo()
        }

        {useCheckMobileScreen() == true
          ? ""
          : <Title color={"black"} align='center' size="h3">{Constants.BannerTitle}</Title >
        }

        <div className={classes.actionContainer}>         
          <Menu>
            <Menu.Target>              
              <ActionIcon color="dark"><IconMenu2 size={16} /></ActionIcon>
            </Menu.Target>
            <Menu.Dropdown className={classes.dropdownMenu}>              
              <Menu.Item component="a"
          href="https://48d37a-2.myshopify.com/"
          target="_blank"icon={<IconShoppingCart size={14} />}>Shop</Menu.Item>
              {/*<Menu.Item icon={<IconInfoCircle size={14} />}>About Us</Menu.Item>
              <Menu.Item icon={<IconComet size={14} />}>Testimonials</Menu.Item>*/}
              <Menu.Divider />
              <Menu.Label>Socials</Menu.Label>
              <Menu.Item component="a"
          href="https://www.instagram.com/pigandponytales/"
          target="_blank"icon={<IconBrandInstagram size={14} />}>Instagram</Menu.Item>
              <Menu.Item component="a"
          href="https://www.facebook.com/pigandponytales"
          target="_blank"icon={<IconBrandFacebook size={14} />}>Facebook</Menu.Item>
              {/*<Menu.Item icon={<IconMail size={14} />}>Email</Menu.Item>*/}
            </Menu.Dropdown>
          </Menu>
        </div>
      </div>
    </div>
  );
}

function MobileBannerLogo() {

  const { classes } = useStyles();

  return (
    <div className={classes.titleSection}>
      <Image
        className={classes.logo}
        radius="md"
        width={50}
        src={logoNoText}
        alt="Logo"
      />
      <div className={classes.title}>
        
        <Text sx={{fontSize: '32px'}}>{Constants.BannerTitle}</Text >
      </div>
    </div>
  )
}

function DesktopBannerLogo() {

  const { classes } = useStyles();

  return (
    <div className={classes.titleSection}>
      <Image
        className={classes.logo}
        radius="md"
        width={50}
        src={logoNoText}
        alt="Logo"
      />
    </div>
  )
}