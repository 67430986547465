import { createStyles, Text, Image, Button, Spoiler, Title, Space, Center, Divider } from '@mantine/core';
import * as Constants from '../constants'
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';
import BookSlideShow from "./carousel/book-slideshow";
import Slideshow1 from '../assets/cover.jpg'
import Slideshow2 from '../assets/gallery_1.jpg'
import Slideshow3 from '../assets/gallery_2.jpg'
import Slideshow4 from '../assets/gallery_3.jpg'

const useStyles = createStyles((theme) => ({
   itemsContainer: {      
      width: '100%',     
      maxWidth: Constants.MaxContentWidth,
      margin: '0 auto',// to center div.        
      padding: '50px',
      paddingTop: '50px',
      paddingBottom: '20px',
      display: 'flex',
      flexdirection: "row",
      justifyContent: "space-between",
      alignItems: 'center'
   },
   slideshowContainer: {
      width: '100%',
      maxWidth: '500px',         
      margin: '0 auto',// to center div.        
      display: 'flex',
      padding: '25px',
      paddingTop: '50px',
      boxSizing: 'border-box'
   },
   image: {
      margin: '0 auto',// to center div.   
   },
   button: {
      paddingTop: '20px',      
   },
   descriptionContainer: {
      maxWidth: '700px',
      flexShrink: 2
   }    
 }));

 const slideShowData = [
   {
     key: '1',
     image: Slideshow1
   },
   {
     key: '2',
     image: Slideshow2
   },
   {
     key: '3',
     image: Slideshow3
   },
   {
     key: '4',
     image: Slideshow4
   }
 ];

export default function Gallery() {

   const { classes } = useStyles();

   return (
      <div      
      style={{width: '100%',          
      display: 'flex',
      paddingBottom: '50px',
      boxShadow: '0px 75px 3px -75px rgba(0, 0, 0, 0.5)',
      flexWrap: 'wrap'}}>
              {
             useCheckMobileScreen() == true ?  <div className={classes.slideshowContainer}> <BookSlideShow itemList={slideShowData} /></div> : ""
          }    
         <div className={classes.itemsContainer}>
            <div className={classes.descriptionContainer}>
               <Title>{Constants.SneezeTitle}</Title>               
               <Space h="md" />
               <Text>
                  {Constants.Blurb}
               </Text>
               <Center className={classes.button}>
                  <Button 
                  color="cyan" 
                  size="lg"
                  component="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://48d37a-2.myshopify.com/" >Shop Now</Button>
               </Center>
               </div>      
            <div>
            {
                useCheckMobileScreen() == true ? "" :<div className={classes.slideshowContainer}> <BookSlideShow itemList={slideShowData} /></div>
             }   
            </div>    
         </div>
                  
      </div>
   );
} 


