import { createStyles, Image  } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      flexShrink: 0
    } 
  }));

  export interface CardItem {
    key: string;
    image: string;
  }
  
  export default function Card({ image }: CardItem) {
  
    const { classes } = useStyles();
  
    return (  
  <Image
  className={classes.card}
      radius="md"
      src= {image}
      alt="Random unsplash image"
  />
    );
  }