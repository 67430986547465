import { Card, Image, Text, Badge, Button, Group, Center } from '@mantine/core';
import StarRatings from 'react-star-ratings'
const styles = {
    card: {
        width: '100%',
        maxWidth: '500px',
        padding: '40px'
        
    },
    star: {
        paddingTop: '20px'
    }
};

export default function Review() {
    return (
        <Card style={styles.card} shadow="sm" p="lg" radius="md" withBorder>
            <Card.Section>

            </Card.Section>

            <Group position="apart" mt="md" mb="xs">
                <Text weight={500}>Gary</Text>
                <Badge color="green" variant="light">
                    Recommends
                </Badge>
            </Group>

            <Text size="sm" color="dimmed">
                With Fjord Tours you can explore more of the magical fjord landscapes with tours and
                activities on and around the fjords of Norway
            </Text>           

            <Center style={styles.star}>
                <StarRatings
            rating={5}
            starRatedColor="#FEE12B"
            numberOfStars={5}
            starDimension='40'
            name='rating'
            />
            </Center>
           
        </Card>
    );
}