import { Grid, createStyles, Title, Center } from "@mantine/core";
import * as Constants from '../constants'
import useCheckMobileScreen from "../hooks/useCheckMobileScreen";
import TestimonialsSlideShow from "./carousel/testimonials-slideshow";
import Review from "./review";

const useStyles = createStyles((theme) => ({
    wrapper: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap'
    },
    itemsContainer: {
        maxWidth: Constants.MaxContentWidth,
        width: '100%',
        margin: '0 auto',// to center div.    
        display: 'flex',
        flexdirection: "row",
        justifyContent: "space-between",
        alignItems: 'center',
        flexWrap:  'wrap',
        paddingBottom: '40px'
    },
    title: {
        paddingBottom: '40px',// to center div.   
        paddingTop: '40px',// to center div.   
        maxWidth: Constants.MaxContentWidth,
        width: '100%',
    },
    slideshowContainerMobile: {        
        width: '100%',
        maxWidth: '500px',         
        margin: '0 auto',// to center div.        
        display: 'flex',
        padding: '25px',
        paddingTop: '30px',
        boxSizing: 'border-box',
        flexdirection: "row",
        flexWrap:  'wrap',
     }
}));

const slideShowData = [
    {
      key: '1',
      image: 'Slideshow1'
    },
    {
      key: '2',
      image: 'Slideshow1'
    },
    {
      key: '3',
      image: 'Slideshow1'
    },
    {
      key: '4',
      image: 'Slideshow1'
    }
  ];

export default function Testimonials() {

    const { classes } = useStyles();

    return (
        <div className={classes.wrapper}>
           
            {useCheckMobileScreen() == true ? <MobileView /> : <DesktopView />}           
        </div>
    );
}


const MobileView = () => {

    const { classes } = useStyles();

    return (      
        <div className={classes.itemsContainer}>
            <div className={classes.slideshowContainerMobile}>
                <Center className={classes.title}>
                    <Title order={1}>Testimonials</Title>
                </Center >
                <TestimonialsSlideShow itemList={slideShowData} />
            </div>            
        </div>
    );
}

const DesktopView = () => {
    const { classes } = useStyles();
    return (
        <div className={classes.itemsContainer}>

            <Center className={classes.title}>
                <Title order={1}>Testimonials</Title>
            </Center >
            <Grid columns={6} align="center">
                <Grid.Col md={6} lg={2}>
                    <Center>
                        <Review />
                    </Center>
                </Grid.Col>
                <Grid.Col md={6} lg={2}>
                    <Center>
                        <Review />
                    </Center>
                </Grid.Col>
                <Grid.Col md={6} lg={2}>
                    <Center>
                        <Review />
                    </Center>
                </Grid.Col>
            </Grid>
        </div>
    );
}