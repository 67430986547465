import { Carousel } from '@mantine/carousel';
import Card, { CardItem } from './slideshow-card';
import Review from '../review';

export interface CardItemList { 
  itemList: CardItem[];
}

export default function TestimonialsSlideShow({itemList}: CardItemList) {

  const slides = itemList.map((item) => (
    <Carousel.Slide key={item.key}>
      <Review />
    </Carousel.Slide>
  ));

  return (
    <Carousel        
      slideSize="100%"
      breakpoints={[{ maxWidth: 'sm', slideSize: '100%', slideGap: 1 }]}
      slideGap="xl"
      align="start"      
      slidesToScroll={1}      
      styles={{
        indicator: {
          width: 12,
          height: 4,
          transition: 'width 250ms ease',
          backgroundColor:'lightgray',        
        },
        controls:{
            paddingLeft: '4px',
            paddingRight: '4px'
        }
      }}
    >
      {slides}
    </Carousel>
  );
}