import { MantineProvider, createStyles, Divider, BackgroundImage } from '@mantine/core';
import Banner from './components/banner';
import BookPreview from './components/book-preview';
import AboutUs from './components/about-us';
import Testimonials from './components/testimonials';
import YellowBg from './assets/red.svg'
import YellowBgRotated from './assets/red-rotated.svg'
import GreenBg from './assets/green.svg'
import GreenBgRotated from './assets/green-rotated.svg'
import './index.css'
import { AboutUsPigAndPony } from './constants';

const useStyles = createStyles((theme) => ({
  wrapper: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap'
  },  
 previewLayer:{
  backgroundImage:`url(${YellowBg})` 
 }
}
));

function App() {

  const { classes } = useStyles();

  return (
    <MantineProvider
      theme={{
        // Override any other properties from default theme        
        spacing: { xs: 15, sm: 20, md: 25, lg: 30, xl: 40 },
        fontFamily: 'Mali',
        headings: { fontFamily: 'Montserrat, sans-serif' },
        colorScheme: 'light'
      }}
    >      
      <div className={classes.wrapper}>      
        <Banner />  
        <BookPreview />        
        <Divider />        
        <AboutUs text={AboutUsPigAndPony} image='' title='About Us'/>
        <Testimonials />
      </div>
    </MantineProvider>
  );
}

export default App;