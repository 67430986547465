
import { Image, Grid, Text, Title, Space,createStyles, useMantineTheme } from "@mantine/core";
import * as Constants from '../constants'
import logo from '../assets/logo.jpg'
import { useMediaQuery } from '@mantine/hooks';
import FadeInWhenVisible from "./fade-when-visible";

const useStyles = createStyles((theme) => ({
    wrapper: {
        width: '100%',       
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: '50px',
       boxShadow: '0px 75px 3px -75px rgba(0, 0, 0, 0.5)',
    },
    itemsContainer: {
        maxWidth: Constants.MaxContentWidth,
        width: '100%',
        margin: '0 auto',// to center div.            
        display: 'flex',
        flexdirection: "row",
        justifyContent: "space-between",
        alignItems: 'center',
        flexWrap:  'wrap',
        paddingBottom: '60px',
    },
    image: {
        margin: '0 auto',// to center div.   
        display: 'flex',
        flexWrap:  'wrap'
    }    
  }
  ));

  export interface AboutUsProps {    
    image: string;
    title: string;
    text: string;
  }

export default function AboutUs({image, text, title} : AboutUsProps) {

    const theme = useMantineTheme();
    const { classes } = useStyles();
    const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);

    let maxWidth = '50%';
    if (mobile)
        maxWidth = '100%';  


    const useStyles2 = createStyles((theme) => ({
        descriptionStyle: {
            width: '100%',
            maxWidth: maxWidth,
            display: 'flex',
            margin: '0 auto',
            flexWrap:  'wrap',
            paddingRight: '25px',
            paddingLeft: '50px'
        }
        }));   

    return (
        <div className={classes.wrapper}>
            <div className={classes.itemsContainer}>                
                <Image
                    className={classes.image}
                    radius="md"
                    width={400}
                    src={logo}
                    alt="Random unsplash image"
                />       

                <FadeInWhenVisible className={useStyles2().classes.descriptionStyle} >
                    <div>
                            <Title order={3}>{title}</Title>
                            <Space h="md" />
                            <Text>
                                {text}
                            </Text>      
                        </div>          
                </FadeInWhenVisible>  
            </div>            
        </div>
    );
} 