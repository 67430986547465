export const Blurb = 'It started with an "AHH", but there was no \'CHOO\'. My sneeze is lost - whatever can I do? \n The story of a young girl who is determined to find her missing sneeze. The playful, rhyming verses and vibrant illustrations will keep you searching for her pesky sneeze right until the very end.'
export const SneezeTitle = 'Where Did My Sneeze Go?'
export const AboutUsTitle = 'About Us'
export const BannerTitle = 'Pig & Pony Tales'

export const MaxContentWidth = '1320px'

export const ColourRegionOffset = "#EEEEEE"
export const ColorBanner = '#2f3433'   

export const AboutUsPigAndPony = "Pig and Pony Tales is a self-publishing company based in Brisbane, Queensland, run by author and illustrator duo Kimberley Dover and Sabrina Nicholetts. Kimberley and Sabrina are cousins who have developed a passion for creating playful children’s books. With favourite animals growing up being pigs and horses - the name Pig and Pony Tales seemed only fitting. Pig and Pony Tales was established in 2022, publishing debut children’s book ‘Where Did My Sneeze Go?’ - the first of many tales to come. "
export const AboutUsKim = "Kimberley was born and raised in sunny Queensland’s city of Ipswich. Working as a Registered Nurse and Midwife, Kimberley started writing children’s books as a hobby until 2022 when her first book ‘Where Did My Sneeze Go?’ was published. Growing up she cherished poems and rhymes, so has adopted a playful rhyming style in her own books that she hopes other children will adore. Kimberley is enjoying the change in writing style from her regular progress notes and plans to write many more children’s books."
export const AboutUsSabrina = "Sabrina Nicholetts is an illustrator and graphic designer based in Queensland, Australia. After finishing her studies and starting her own graphic design business, Sabrina realised that illustration was something she could really pour her creativity into. Sabrina was instantly drawn to illustrating children’s books - drawing inspiration from the bright colours, quirky characters, and nostalgia that their illustrations often portray. 2022 produced Sabrina’s first published work “Where Did My Sneeze Go?” which was written by business partner and cousin Kimberley Dover."

