import React, { CSSProperties, useEffect } from "react";
import ReactDOM from "react-dom";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { createStyles } from "@mantine/core";

interface Child {
    children: JSX.Element;    
    className: string;
  }

export default function FadeInWhenVisible({ children, className } : Child) {
    const controls = useAnimation();
    const [ref, inView] = useInView();
   
    useEffect(() => {
      if (inView) {
        controls.start("visible");
      } else {
        controls.start("hidden");
      }
    }, [controls, inView]);
  
    return (
      <motion.div        
      className={className}
        ref={ref}
        animate={controls}
        initial="hidden"
        transition={{ duration: 0.3, ease: "easeIn" }}
        variants={{
          visible: { opacity: 1, scale: 1 },
          hidden: { opacity: 0, scale: 0 }
        }}
      >
        {children}
      </motion.div>
    );
  }